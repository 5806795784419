/* globals google */

const preferredStore = require('./preferred-store');
require('bootstrap/js/src/collapse.js');
window.jQuery = window.$ = require('jquery');
var storeLocatorGoogleCount = 0;
var storeLocatorAutoBindGoogleCount = 0;

function getUrlParam(searchParam) {
	const params = window.location.search.substring(1).split('&');
	let val = null;
	params.forEach(param => {
		const keyValue = param.split('=');
		if (keyValue[0] === searchParam) {
			val = decodeURIComponent(keyValue[1]).replace(/\++/g, ' ');
		}
	});

	return val;
}

function getLatLng() {
	// Init on brisbane

	let lat = 27.4698;
	let long = 153.0251;

	const latParam = getUrlParam('lat');
	const longParam = getUrlParam('long');
	if (latParam && longParam) {
		lat = parseFloat(latParam);
		long = parseFloat(longParam);
		$('#store-hidden-lat').val(latParam);
		$('#store-hidden-long').val(longParam);
		$('#store-search-button').attr('disabled', false);
	} else if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(function(position) {
			position.lat = position.coords.latitude;
			position.long = position.coords.longitude;
		});
	}
	return {
		lat: lat,
		lng: long,
	};
}
/**
 * Uses google maps api to render a map
 */
function maps() {
	if (typeof google === 'undefined' && storeLocatorGoogleCount < 1) {
		storeLocatorGoogleCount += 1;
		setTimeout(maps, 3000);
		return;
	}

	if (typeof google === 'undefined') {
		return;
	}

	const infowindow = new google.maps.InfoWindow();
	const position = getLatLng();
	const mapOptions = {
		scrollwheel: false,
		zoom: 4,
		center: position,
	};
	const mapEl = $('#map-canvas');
	const map = new google.maps.Map(mapEl[0], mapOptions);
	let mapdiv = $('#map-canvas').attr('data-locations');

	mapdiv = JSON.parse(mapdiv);

	const bounds = new google.maps.LatLngBounds();

	// Customized google map marker icon with svg format
	const markerImg = {
		path:
			'M13.8,40.2C2.2,23.3,0,21.6,0,15.4C0,6.9,6.9,0,15.4,0s15.4,6.9,15.4,15.4c0,6.2-2.2,7.9-13.8,24.8C16.2,41.3,14.6,41.3,13.8,40.2L13.8,40.2z',
		fillColor: '#ED1C25',
		fillOpacity: 1,
		scale: 1.1,
		strokeColor: 'white',
		strokeWeight: 1,
		anchor: new google.maps.Point(13, 30),
		labelOrigin: new google.maps.Point(15, 15),
	};

	Object.keys(mapdiv).forEach(function(key) {
		const item = mapdiv[key];
		const lable = parseInt(key, 10) + 1;
		const storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
		const marker = new google.maps.Marker({
			position: storeLocation,
			map: map,
			title: item.name,
			icon: markerImg,
			label: {text: lable.toString(), color: 'white', fontSize: '18px'},
		});

		marker.addListener('click', function() {
			infowindow.setOptions({
				content: item.infoWindowHtml,
			});
			infowindow.open(map, marker);
		});

		// Create a minimum bound based on a set of storeLocations
		bounds.extend(marker.position);
	});
	// Fit the all the store marks in the center of a minimum bounds when any store has been found.
	map.fitBounds(bounds);
	google.maps.event.trigger(map, 'resize');
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
	const $resultsDiv = $('.results');
	const $mapDiv = $('#map-canvas');
	const hasResults = data.stores.length > 0;

	if (!hasResults) {
		$('.store-locator-no-results').show();
	} else {
		$('.store-locator-no-results').hide();
	}

	$resultsDiv
		.empty()
		.data('has-results', hasResults)
		.data('radius', data.radius)
		.data('search-key', data.searchKey);

	$mapDiv.attr('data-locations', data.locations);

	maps();

	if (data.storesResultsHtml) {
		$resultsDiv.append(data.storesResultsHtml);
		preferredStore.listenToMarkStore();
	}
}

/**
 * Search for stores with new zip code
 * @returns {boolean} false to prevent default event
 */
function search() {
	const $form = $('#store-locator-form');
	const hasOutdoor = $('#hasOutdoorFilter').is(':checked');
	const hasAssembly = $('#hasAssemblyFilter').is(':checked');
	const hasSignature = $('#hasSignatureFilter').is(':checked');
	const data = `${$form.serialize()}${hasOutdoor ? '&hasOutdoor=true' : ''}${hasAssembly ? '&hasAssembly=true' : ''}${
		hasSignature ? '&hasSignature=true' : ''
	}`;

	$.ajax({
		url: $form.attr('action'),
		type: $form.attr('method'),
		data: data,
		dataType: 'json',
		success: function(data) {
			updateStoresResults(data);
		},
	});
	return false;
}

function watchTheMap() {
	let ms = 1000;
	do {
		setTimeout(function() {
			const children = $('#map-canvas').children();
			if (!children.length) {
				maps();
			}
		}, ms);
		ms += 500;
	} while (ms < 5000);
}

module.exports = {
	init: function() {
		if (!$('#store-locator-mini').length) {
			const suggestion = getUrlParam('suggestion');
			const address = getUrlParam('address');

			if (suggestion || address) {
				$('#store-address').val(suggestion || address);
			}
			$('#store-address').focus();
			if ($('#map-canvas').data('has-google-api')) {
				maps();
				watchTheMap();
			}
			if (!$('.results').data('has-results')) {
				$('.store-locator-no-results').show();
			}
		}
	},
	detectLocation: function() {
		// clicking on detect location.
		$('#store-location-button').on('click', function() {
			if (!navigator.geolocation) {
				return;
			}
			navigator.geolocation.getCurrentPosition(function(position) {
				$('#store-hidden-lat').val(position.coords.latitude);
				$('#store-hidden-long').val(position.coords.longitude);

				if (!$('#store-locator-mini').length) {
					$('#store-hidden-suggestion').val('');
					$('#store-address').val('');
					search();
				} else {
					$('#store-locator-form').submit();
				}
			},
			function (error) {
				// Handle different error cases
				switch(error.code) {
					case error.PERMISSION_DENIED:
						alert("Permission to access location was denied. Please allow location access to use this feature.");
						break;
					case error.POSITION_UNAVAILABLE:
						alert("Location information is unavailable.");
						break;
					case error.TIMEOUT:
						alert("The request to get your location timed out.");
						break;
					case error.UNKNOWN_ERROR:
						alert("An unknown error occurred.");
						break;
				}
			});
		});
	},
	search: function() {
		$('#store-search-button').click(function(e) {
			if (!$('#store-locator-mini').length) {
				e.preventDefault();
				const suggestedVal = $('#store-hidden-suggestion').val();
				if (suggestedVal) {
					$('#store-address').val(suggestedVal);
				}
				search();
			}
		});
	},
	filter: function() {
		$('#hasSignatureFilter, #hasOutdoorFilter, #hasAssemblyFilter').change(function() {
			search();
		});
	},
	bindAutocomplete: function() {
		if (typeof google === 'undefined' && storeLocatorAutoBindGoogleCount < 1) {
			storeLocatorAutoBindGoogleCount += 1;
			setTimeout(this.bindAutocomplete, 3000);
			return;
		}

		if (typeof google === 'undefined') {
			return;
		}

		if (typeof $('#store-address')[0] !== 'undefined') {
			const input = $('#store-address')[0];
			var defaultBounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(-33.8902, 151.1759),
				new google.maps.LatLng(-33.8474, 151.2631));
			var options = {bounds: defaultBounds};
			const autocomplete = new google.maps.places.Autocomplete(input, options);
			autocomplete.setComponentRestrictions({'country': ['au']});
			autocomplete.setFields(['geometry']);

			const autocompleteService = new google.maps.places.AutocompleteService();
			const placesService = $('.map-canvas').length
				? new google.maps.places.PlacesService($('.map-canvas')[0])
				: new google.maps.places.PlacesService($('#hidden-map')[0]);

			const setFields = (location = {}, suggestion) => {
				$('#store-hidden-lat').val(location.lat);
				$('#store-hidden-long').val(location.lng);
				$('#store-hidden-suggestion').val(suggestion);
				$('#store-search-button').attr('disabled', !location.lat || !location.lng);
			};

			autocomplete.addListener('place_changed', function() {
				const place = autocomplete.getPlace();
				if (!place.geometry) {
					setFields();
				} else {
					setFields(place.geometry.location, place.formatted_address);
				}
			});
		}
    },
};
