const processInclude = require('sfraScripts/util');
const modules = require('./utils/bootstrapper').modules;
const gtm = require('./components/gtm');
const {isGTMEnabled} = require('./components/helpers');

$(document).ready(function() {
	processInclude(require('./store/storeLocator'));
});

if (isGTMEnabled()) {
	Object.assign(modules, {
		gtm: gtm.default,
		gtmSaveData: gtm.saveData,
		gtmApplyData: gtm.applyData,
		gtmClearData: gtm.clearData,
	});
}

require('./utils/bootstrapper').apply();
